<template>
  <div class="mb-5">
    <!-- v-for="group in 3" :key="group" -->
    <b-row class="my-2">
      <b-col md="12">
        <h1 class="text-center">
          Veuillez vous assurer que les informations entrées sont correctes puis
          enregistrez le formulaire
        </h1>
      </b-col></b-row
    >
    <b-card class="preview-document">
      <!-- entete -->
      <!-- <div class="d-flex justify-content-between">
        <div class="col-4">
          <AppLogo />
        </div>
        <div class="col-4 text-right">
          O1 BP 2037 <br>
          Cotonou, Bénin <br>
          Tel : +229 21 30 70 13 <br>
          +229 21 30 70 10 <br>
          mtca.sp@gouv.bj
        </div>
      </div> -->

      <b-row>
        <b-col cols="12">
          <!-- <div class="d-flex justify-content-center text-center mb-3 mt-1">
            <div>
              <h1> FICHE D'ENQUETE SUR LE TOURISME INTERNE </h1>
            </div>
          </div>  -->
          <!-- content-->
          <div v-if="infos" class="pl-1 pr-1">
            <!-- identification -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong> I- IDENTIFICATION </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('identification')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6" class="mb-1">
                  Nom: <strong>{{ infos.firstname }}</strong>
                </b-col>
                <b-col md="6">
                  Prénom(s):
                  <strong>{{ infos.lastname }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- classification des voyages/excursions -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>
                    II- CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS
                  </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('classification')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <!-- voyage dans le dernier trimestre -->
                <b-col
                  md="12"
                  class="mb-1"
                  v-if="infos.voyagesDernierTrimestre"
                >
                  Pouvez-vous nous dire si vous ou toute autre personne de votre
                  foyer avez réalisé un voyage ou une excursion au cours du
                  dernier trimestre ?:
                  <strong>{{ infos.voyagesDernierTrimestre }}</strong>
                </b-col>
                <!-- raison du non voyage -->
                <b-col
                  md="12"
                  class="mb-1"
                  v-if="infos.voyagesDernierTrimestre == 'Non'"
                >
                  Raison pour laquelle vous n'avez pas réalisé de voyage au
                  cours du dernier trimestre:
                  <strong>{{ infos.raisonNonVoyage }}</strong>
                </b-col>

                <!-- précisions -->

                <b-col md="12" v-if="infos.voyagesDernierTrimestre === 'Oui'"
                  >Précisez si vous avez effectué :</b-col
                >

                <b-col
                  md="3"
                  v-if="
                    infos.voyagesDernierTrimestre === 'Oui' &&
                    infos.voyageBenin.statut
                  "
                >
                  Des voyages au Bénin
                  <strong
                    >{{ infos.voyageBenin.statut }}
                    {{
                      infos.voyageBenin.statut == "Oui"
                        ? infos.voyageBenin.nombre
                        : ""
                    }}
                  </strong>
                </b-col>

                <b-col
                  md="3"
                  v-if="
                    infos.voyagesDernierTrimestre == 'Oui' &&
                    infos.voyageEtranger.statut
                  "
                >
                  Des voyages à l'étranger
                  <strong
                    >{{ infos.voyageEtranger.statut }}
                    {{
                      infos.voyageEtranger.statut == "Oui"
                        ? infos.voyageEtranger.nombre
                        : ""
                    }}
                  </strong>
                </b-col>

                <b-col
                  md="3"
                  v-if="
                    infos.voyagesDernierTrimestre == 'Oui' &&
                    infos.excursionBenin.statut
                  "
                >
                  Des excursions au Bénin
                  <strong
                    >{{ infos.excursionBenin.statut }}
                    {{
                      infos.excursionBenin.statut == "Oui"
                        ? infos.excursionBenin.nombre
                        : ""
                    }}
                  </strong>
                </b-col>

                <b-col
                  md="3"
                  v-if="
                    infos.voyagesDernierTrimestre == 'Oui' &&
                    infos.excursionEtranger.statut
                  "
                >
                  Des excursions au Bénin
                  <strong
                    >{{ infos.excursionEtranger.statut }}
                    {{
                      infos.excursionEtranger.statut == "Oui"
                        ? infos.excursionEtranger.nombre
                        : ""
                    }}
                  </strong>
                </b-col>
              </b-row>
            </div>

            <!-- classification des voyages/excursions -->
            <div v-if="infos.voyagesDernierTrimestre == 'Oui'">
              <!-- tableau 1 -->
              <div class="bg-primary" v-if="infos.voyageBenin.statut == 'Oui'">
                <b-row class="text-center mt-1 p-1">
                  <b-col
                    md="10"
                    class="text-left text-white d-flex align-items-center"
                  >
                    <strong>
                      III- DETAILS SUR LES VOYAGES ET LES DEPENSES AU BENIN
                    </strong>
                  </b-col>
                  <b-col md="2" class="text-right text-white pr-2">
                    <b-img
                      title="Modifier cette section"
                      class="cursor-pointer"
                      width="20"
                      height="20"
                      :src="editIcon"
                      @click="editSection('tableau1')"
                    />
                  </b-col>
                </b-row>
              </div>

              <div
                class="customborder1 p-2"
                v-if="infos.voyageBenin.statut == 'Oui'"
              >
                <div class="table-responsive text-center">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">301</th>
                        <th scope="col">N° de série du voyage</th>
                        <th scope="col">Voyage(1)</th>
                        <th scope="col">Voyage(2)</th>
                        <th scope="col">Voyage(3)</th>
                        <th scope="col">Voyage(4)</th>
                        <th scope="col">Voyage(5)</th>
                        <th scope="col">Voyage(6)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- nbre personnes voyages -->
                      <tr>
                        <th scope="row">302</th>
                        <td>Nb de personnes ayant effectué ce voyage</td>
                        <td>{{ infos.tableau1[0].nombrePersonne }}</td>
                        <td>{{ infos.tableau1[1].nombrePersonne }}</td>
                        <td>{{ infos.tableau1[2].nombrePersonne }}</td>
                        <td>{{ infos.tableau1[3].nombrePersonne }}</td>
                        <td>{{ infos.tableau1[4].nombrePersonne }}</td>
                        <td>{{ infos.tableau1[5].nombrePersonne }}</td>
                      </tr>
                      <!-- motif voyage -->
                      <tr>
                        <th scope="row"></th>
                        <td>Motif principal du voyage</td>
                        <td>{{ infos.tableau1[0].motifVisite }}</td>
                        <td>{{ infos.tableau1[1].motifVisite }}</td>
                        <td>{{ infos.tableau1[2].motifVisite }}</td>
                        <td>{{ infos.tableau1[3].motifVisite }}</td>
                        <td>{{ infos.tableau1[4].motifVisite }}</td>
                        <td>{{ infos.tableau1[5].motifVisite }}</td>
                      </tr>
                      <!-- destination principale -->
                      <tr>
                        <th scope="row">303</th>
                        <td>Quelle a été la destination principale ?</td>
                        <td>{{ infos.tableau1[0].destinationPrincipale }}</td>
                        <td>{{ infos.tableau1[1].destinationPrincipale }}</td>
                        <td>{{ infos.tableau1[2].destinationPrincipale }}</td>
                        <td>{{ infos.tableau1[3].destinationPrincipale }}</td>
                        <td>{{ infos.tableau1[4].destinationPrincipale }}</td>
                        <td>{{ infos.tableau1[5].destinationPrincipale }}</td>
                      </tr>
                      <!-- distance km -->
                      <tr>
                        <th scope="row">304</th>
                        <td>Quelle a été la distance ? (Km)</td>
                        <td>{{ infos.tableau1[0].distanceKm }}</td>
                        <td>{{ infos.tableau1[1].distanceKm }}</td>
                        <td>{{ infos.tableau1[2].distanceKm }}</td>
                        <td>{{ infos.tableau1[3].distanceKm }}</td>
                        <td>{{ infos.tableau1[4].distanceKm }}</td>
                        <td>{{ infos.tableau1[5].distanceKm }}</td>
                      </tr>
                      <!-- type transport -->
                      <tr>
                        <th scope="row">305</th>
                        <td>Type de transport</td>
                        <td>{{ infos.tableau1[0].typeTransport }}</td>
                        <td>{{ infos.tableau1[1].typeTransport }}</td>
                        <td>{{ infos.tableau1[2].typeTransport }}</td>
                        <td>{{ infos.tableau1[3].typeTransport }}</td>
                        <td>{{ infos.tableau1[4].typeTransport }}</td>
                        <td>{{ infos.tableau1[5].typeTransport }}</td>
                      </tr>
                      <!-- nobre nuite -->
                      <tr>
                        <th scope="row">306</th>
                        <td>Nombre de nuits ?</td>
                        <td>{{ infos.tableau1[0].nombreNuits }}</td>
                        <td>{{ infos.tableau1[1].nombreNuits }}</td>
                        <td>{{ infos.tableau1[2].nombreNuits }}</td>
                        <td>{{ infos.tableau1[3].nombreNuits }}</td>
                        <td>{{ infos.tableau1[4].nombreNuits }}</td>
                        <td>{{ infos.tableau1[5].nombreNuits }}</td>
                      </tr>
                      <!-- lieu residence -->
                      <tr>
                        <th scope="row">307</th>
                        <td>Où avez-vous dormi ?</td>
                        <td>{{ infos.tableau1[0].lieuResidence }}</td>
                        <td>{{ infos.tableau1[1].lieuResidence }}</td>
                        <td>{{ infos.tableau1[2].lieuResidence }}</td>
                        <td>{{ infos.tableau1[3].lieuResidence }}</td>
                        <td>{{ infos.tableau1[4].lieuResidence }}</td>
                        <td>{{ infos.tableau1[5].lieuResidence }}</td>
                      </tr>
                      <!-- forfait touristique -->
                      <tr>
                        <th scope="row">308</th>
                        <td>
                          S’agissait-il d’un forfait touristique tout compris ?
                        </td>
                        <td>{{ infos.tableau1[0].forfaitTouristique }}</td>
                        <td>{{ infos.tableau1[1].forfaitTouristique }}</td>
                        <td>{{ infos.tableau1[2].forfaitTouristique }}</td>
                        <td>{{ infos.tableau1[3].forfaitTouristique }}</td>
                        <td>{{ infos.tableau1[4].forfaitTouristique }}</td>
                        <td>{{ infos.tableau1[5].forfaitTouristique }}</td>
                      </tr>
                      <!-- montant du forfait -->
                      <tr>
                        <th scope="row">309</th>
                        <td>
                          Montant total payé pour le forfait touristique ?
                        </td>
                        <td>{{ infos.tableau1[0].montantForfait }}</td>
                        <td>{{ infos.tableau1[1].montantForfait }}</td>
                        <td>{{ infos.tableau1[2].montantForfait }}</td>
                        <td>{{ infos.tableau1[3].montantForfait }}</td>
                        <td>{{ infos.tableau1[4].montantForfait }}</td>
                        <td>{{ infos.tableau1[5].montantForfait }}</td>
                      </tr>
                      <!-- cout total du voyage -->
                      <tr>
                        <th scope="row">310</th>
                        <td>Cout total du voyage ?</td>
                        <td>{{ infos.tableau1[0].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau1[1].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau1[2].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau1[3].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau1[4].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau1[5].coutTotalVoyage }}</td>
                      </tr>
                      <!-- poste de dépenses -->
                      <tr>
                        <th scope="row" rowspan="8">311</th>
                        <td>1. Hébergement</td>
                        <td>{{ infos.tableau1[0].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau1[1].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau1[2].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau1[3].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau1[4].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau1[5].posteDepenseHebergement }}</td>
                      </tr>
                      <tr>
                        <td>2. Repas et boissons</td>
                        <td>
                          {{ infos.tableau1[0].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau1[1].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau1[2].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau1[3].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau1[4].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau1[5].posteDepenseRepasBoissons }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3. Achats liés au voyage touristique (nécessaires de
                          voyage, cadeaux, souvenirs, etc.)
                        </td>
                        <td>
                          {{ infos.tableau1[0].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau1[1].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau1[2].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau1[3].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau1[4].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau1[5].posteDepenseAchatsVoyage }}
                        </td>
                      </tr>
                      <tr>
                        <td>4. Activités culturelles</td>
                        <td>
                          {{
                            infos.tableau1[0].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau1[1].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau1[2].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau1[3].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau1[4].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau1[5].posteDepenseActivitesCulturelles
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>5. Activités sportives</td>
                        <td>
                          {{ infos.tableau1[0].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau1[1].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau1[2].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau1[3].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau1[4].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau1[5].posteDepenseActivitesSportives }}
                        </td>
                      </tr>
                      <tr>
                        <td>6. Dépenses médicales</td>
                        <td>
                          {{ infos.tableau1[0].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau1[1].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau1[2].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau1[3].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau1[4].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau1[5].posteDepenseDepensesMedicales }}
                        </td>
                      </tr>
                      <tr>
                        <td>7. Education/Formation</td>
                        <td>
                          {{ infos.tableau1[0].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau1[1].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau1[2].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau1[3].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau1[4].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau1[5].posteDepenseEducationFormation }}
                        </td>
                      </tr>
                      <tr>
                        <td>8. Autres</td>
                        <td>{{ infos.tableau1[0].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau1[1].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau1[2].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau1[3].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau1[4].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau1[5].posteDepenseAutres }}</td>
                      </tr>
                      <!-- nbre de visites -->
                      <tr>
                        <th scope="row">312</th>
                        <td>
                          Combien de fois avez-vous voyagé vers cette
                          destination?
                        </td>
                        <td>
                          {{ infos.tableau1[0].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau1[1].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau1[2].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau1[3].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau1[4].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau1[5].nombreVoyagesVersDestination }}
                        </td>
                      </tr>
                      <!-- activite réalisee -->
                      <tr>
                        <th scope="row">313</th>
                        <td>Quelles activités avez-vous réalisés ?</td>
                        <td>
                          <span
                            v-for="activite in infos.tableau1[0]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau1[1]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau1[2]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau1[3]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau1[4]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau1[5]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                      </tr>
                      <!-- satisfactions -->
                      <tr>
                        <th scope="row">314</th>
                        <td>Satisfaction quant au voyage ?</td>
                        <td>{{ infos.tableau1[0].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau1[1].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau1[2].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau1[3].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau1[4].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau1[5].satisfactionVoyage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- tableau 2 -->
              <div
                class="bg-primary"
                v-if="infos.voyageEtranger.statut == 'Oui'"
              >
                <b-row class="text-center mt-1 p-1">
                  <b-col
                    md="10"
                    class="text-left text-white d-flex align-items-center"
                  >
                    <strong>
                      III- DETAILS SUR LES VOYAGES ET LES DEPENSES A L'ETRANGER
                    </strong>
                  </b-col>
                  <b-col md="2" class="text-right text-white pr-2">
                    <b-img
                      title="Modifier cette section"
                      class="cursor-pointer"
                      width="20"
                      height="20"
                      :src="editIcon"
                      @click="editSection('tableau2')"
                    />
                  </b-col>
                </b-row>
              </div>

              <div
                class="customborder1 p-2"
                v-if="infos.voyageEtranger.statut == 'Oui'"
              >
                <div class="table-responsive text-center">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">401</th>
                        <th scope="col">N° de série du voyage</th>
                        <th scope="col">Voyage(1)</th>
                        <th scope="col">Voyage(2)</th>
                        <th scope="col">Voyage(3)</th>
                        <th scope="col">Voyage(4)</th>
                        <th scope="col">Voyage(5)</th>
                        <th scope="col">Voyage(6)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- nbre personnes voyages -->
                      <tr>
                        <th scope="row">402</th>
                        <td>Nb de personnes ayant effectué ce voyage</td>
                        <td>{{ infos.tableau2[0].nombrePersonne }}</td>
                        <td>{{ infos.tableau2[1].nombrePersonne }}</td>
                        <td>{{ infos.tableau2[2].nombrePersonne }}</td>
                        <td>{{ infos.tableau2[3].nombrePersonne }}</td>
                        <td>{{ infos.tableau2[4].nombrePersonne }}</td>
                        <td>{{ infos.tableau2[5].nombrePersonne }}</td>
                      </tr>
                      <!-- motif voyage -->
                      <tr>
                        <th scope="row"></th>
                        <td>Motif principal du voyage</td>
                        <td>{{ infos.tableau2[0].motifVisite }}</td>
                        <td>{{ infos.tableau2[1].motifVisite }}</td>
                        <td>{{ infos.tableau2[2].motifVisite }}</td>
                        <td>{{ infos.tableau2[3].motifVisite }}</td>
                        <td>{{ infos.tableau2[4].motifVisite }}</td>
                        <td>{{ infos.tableau2[5].motifVisite }}</td>
                      </tr>
                      <!-- destination principale -->
                      <tr>
                        <th scope="row">403</th>
                        <td>Quelle a été la destination principale ?</td>
                        <td>{{ infos.tableau2[0].destinationPrincipale }}</td>
                        <td>{{ infos.tableau2[1].destinationPrincipale }}</td>
                        <td>{{ infos.tableau2[2].destinationPrincipale }}</td>
                        <td>{{ infos.tableau2[3].destinationPrincipale }}</td>
                        <td>{{ infos.tableau2[4].destinationPrincipale }}</td>
                        <td>{{ infos.tableau2[5].destinationPrincipale }}</td>
                      </tr>
                      <!-- distance km -->
                      <tr>
                        <th scope="row">404</th>
                        <td>Quelle a été la distance ? (Km)</td>
                        <td>{{ infos.tableau2[0].distanceKm }}</td>
                        <td>{{ infos.tableau2[1].distanceKm }}</td>
                        <td>{{ infos.tableau2[2].distanceKm }}</td>
                        <td>{{ infos.tableau2[3].distanceKm }}</td>
                        <td>{{ infos.tableau2[4].distanceKm }}</td>
                        <td>{{ infos.tableau2[5].distanceKm }}</td>
                      </tr>
                      <!-- type transport -->
                      <tr>
                        <th scope="row">405</th>
                        <td>Type de transport</td>
                        <td>{{ infos.tableau2[0].typeTransport }}</td>
                        <td>{{ infos.tableau2[1].typeTransport }}</td>
                        <td>{{ infos.tableau2[2].typeTransport }}</td>
                        <td>{{ infos.tableau2[3].typeTransport }}</td>
                        <td>{{ infos.tableau2[4].typeTransport }}</td>
                        <td>{{ infos.tableau2[5].typeTransport }}</td>
                      </tr>
                      <!-- nobre nuite -->
                      <tr>
                        <th scope="row">406</th>
                        <td>Nombre de nuits ?</td>
                        <td>{{ infos.tableau2[0].nombreNuits }}</td>
                        <td>{{ infos.tableau2[1].nombreNuits }}</td>
                        <td>{{ infos.tableau2[2].nombreNuits }}</td>
                        <td>{{ infos.tableau2[3].nombreNuits }}</td>
                        <td>{{ infos.tableau2[4].nombreNuits }}</td>
                        <td>{{ infos.tableau2[5].nombreNuits }}</td>
                      </tr>
                      <!-- lieu residence -->
                      <tr>
                        <th scope="row">407</th>
                        <td>Où avez-vous dormi ?</td>
                        <td>{{ infos.tableau2[0].lieuResidence }}</td>
                        <td>{{ infos.tableau2[1].lieuResidence }}</td>
                        <td>{{ infos.tableau2[2].lieuResidence }}</td>
                        <td>{{ infos.tableau2[3].lieuResidence }}</td>
                        <td>{{ infos.tableau2[4].lieuResidence }}</td>
                        <td>{{ infos.tableau2[5].lieuResidence }}</td>
                      </tr>
                      <!-- forfait touristique -->
                      <tr>
                        <th scope="row">408</th>
                        <td>
                          S’agissait-il d’un forfait touristique tout compris ?
                        </td>
                        <td>{{ infos.tableau2[0].forfaitTouristique }}</td>
                        <td>{{ infos.tableau2[1].forfaitTouristique }}</td>
                        <td>{{ infos.tableau2[2].forfaitTouristique }}</td>
                        <td>{{ infos.tableau2[3].forfaitTouristique }}</td>
                        <td>{{ infos.tableau2[4].forfaitTouristique }}</td>
                        <td>{{ infos.tableau2[5].forfaitTouristique }}</td>
                      </tr>
                      <!-- montant du forfait -->
                      <tr>
                        <th scope="row">409</th>
                        <td>
                          Montant total payé pour le forfait touristique ?
                        </td>
                        <td>{{ infos.tableau2[0].montantForfait }}</td>
                        <td>{{ infos.tableau2[1].montantForfait }}</td>
                        <td>{{ infos.tableau2[2].montantForfait }}</td>
                        <td>{{ infos.tableau2[3].montantForfait }}</td>
                        <td>{{ infos.tableau2[4].montantForfait }}</td>
                        <td>{{ infos.tableau2[5].montantForfait }}</td>
                      </tr>
                      <!-- cout total du voyage -->
                      <tr>
                        <th scope="row">410</th>
                        <td>Cout total du voyage ?</td>
                        <td>{{ infos.tableau2[0].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau2[1].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau2[2].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau2[3].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau2[4].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau2[5].coutTotalVoyage }}</td>
                      </tr>
                      <!-- poste de dépenses -->
                      <tr>
                        <th scope="row" rowspan="8">411</th>
                        <td>1. Hébergement</td>
                        <td>{{ infos.tableau2[0].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau2[1].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau2[2].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau2[3].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau2[4].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau2[5].posteDepenseHebergement }}</td>
                      </tr>
                      <tr>
                        <td>2. Repas et boissons</td>
                        <td>
                          {{ infos.tableau2[0].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau2[1].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau2[2].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau2[3].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau2[4].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau2[5].posteDepenseRepasBoissons }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3. Achats liés au voyage touristique (nécessaires de
                          voyage, cadeaux, souvenirs, etc.)
                        </td>
                        <td>
                          {{ infos.tableau2[0].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau2[1].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau2[2].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau2[3].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau2[4].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau2[5].posteDepenseAchatsVoyage }}
                        </td>
                      </tr>
                      <tr>
                        <td>4. Activités culturelles</td>
                        <td>
                          {{
                            infos.tableau2[0].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau2[1].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau2[2].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau2[3].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau2[4].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau2[5].posteDepenseActivitesCulturelles
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>5. Activités sportives</td>
                        <td>
                          {{ infos.tableau2[0].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau2[1].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau2[2].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau2[3].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau2[4].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau2[5].posteDepenseActivitesSportives }}
                        </td>
                      </tr>
                      <tr>
                        <td>6. Dépenses médicales</td>
                        <td>
                          {{ infos.tableau2[0].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau2[1].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau2[2].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau2[3].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau2[4].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau2[5].posteDepenseDepensesMedicales }}
                        </td>
                      </tr>
                      <tr>
                        <td>7. Education/Formation</td>
                        <td>
                          {{ infos.tableau2[0].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau2[1].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau2[2].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau2[3].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau2[4].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau2[5].posteDepenseEducationFormation }}
                        </td>
                      </tr>
                      <tr>
                        <td>8. Autres</td>
                        <td>{{ infos.tableau2[0].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau2[1].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau2[2].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau2[3].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau2[4].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau2[5].posteDepenseAutres }}</td>
                      </tr>
                      <!-- nbre de visites -->
                      <tr>
                        <th scope="row">412</th>
                        <td>
                          Combien de fois avez-vous voyagé vers cette
                          destination?
                        </td>
                        <td>
                          {{ infos.tableau2[0].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau2[1].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau2[2].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau2[3].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau2[4].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau2[5].nombreVoyagesVersDestination }}
                        </td>
                      </tr>
                      <!-- activite réalisee -->
                      <tr>
                        <th scope="row">413</th>
                        <td>Quelles activités avez-vous réalisés ?</td>
                        <td>
                          <span
                            v-for="activite in infos.tableau2[0]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau2[1]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau2[2]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau2[3]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau2[4]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau2[5]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                      </tr>
                      <!-- satisfactions -->
                      <tr>
                        <th scope="row">414</th>
                        <td>Satisfaction quant au voyage ?</td>
                        <td>{{ infos.tableau2[0].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau2[1].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau2[2].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau2[3].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau2[4].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau2[5].satisfactionVoyage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- tableau 3 -->
              <div
                class="bg-primary"
                v-if="infos.excursionBenin.statut == 'Oui'"
              >
                <b-row class="text-center mt-1 p-1">
                  <b-col
                    md="10"
                    class="text-left text-white d-flex align-items-center"
                  >
                    <strong> III- DETAILS SUR LES EXCURSIONS AU BENIN </strong>
                  </b-col>
                  <b-col md="2" class="text-right text-white pr-2">
                    <b-img
                      title="Modifier cette section"
                      class="cursor-pointer"
                      width="20"
                      height="20"
                      :src="editIcon"
                      @click="editSection('tableau3')"
                    />
                  </b-col>
                </b-row>
              </div>

              <div
                class="customborder1 p-2"
                v-if="infos.excursionBenin.statut == 'Oui'"
              >
                <div class="table-responsive text-center">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">501</th>
                        <th scope="col">N° de série du voyage</th>
                        <th scope="col">Voyage(1)</th>
                        <th scope="col">Voyage(2)</th>
                        <th scope="col">Voyage(3)</th>
                        <th scope="col">Voyage(4)</th>
                        <th scope="col">Voyage(5)</th>
                        <th scope="col">Voyage(6)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- nbre personnes voyages -->
                      <tr>
                        <th scope="row">502</th>
                        <td>Nb de personnes ayant effectué ce voyage</td>
                        <td>{{ infos.tableau3[0].nombrePersonne }}</td>
                        <td>{{ infos.tableau3[1].nombrePersonne }}</td>
                        <td>{{ infos.tableau3[2].nombrePersonne }}</td>
                        <td>{{ infos.tableau3[3].nombrePersonne }}</td>
                        <td>{{ infos.tableau3[4].nombrePersonne }}</td>
                        <td>{{ infos.tableau3[5].nombrePersonne }}</td>
                      </tr>
                      <!-- motif voyage -->
                      <tr>
                        <th scope="row"></th>
                        <td>Motif principal du voyage</td>
                        <td>{{ infos.tableau3[0].motifVisite }}</td>
                        <td>{{ infos.tableau3[1].motifVisite }}</td>
                        <td>{{ infos.tableau3[2].motifVisite }}</td>
                        <td>{{ infos.tableau3[3].motifVisite }}</td>
                        <td>{{ infos.tableau3[4].motifVisite }}</td>
                        <td>{{ infos.tableau3[5].motifVisite }}</td>
                      </tr>
                      <!-- destination principale -->
                      <tr>
                        <th scope="row">503</th>
                        <td>Quelle a été la destination principale ?</td>
                        <td>{{ infos.tableau3[0].destinationPrincipale }}</td>
                        <td>{{ infos.tableau3[1].destinationPrincipale }}</td>
                        <td>{{ infos.tableau3[2].destinationPrincipale }}</td>
                        <td>{{ infos.tableau3[3].destinationPrincipale }}</td>
                        <td>{{ infos.tableau3[4].destinationPrincipale }}</td>
                        <td>{{ infos.tableau3[5].destinationPrincipale }}</td>
                      </tr>
                      <!-- distance km -->
                      <tr>
                        <th scope="row">504</th>
                        <td>Quelle a été la distance ? (Km)</td>
                        <td>{{ infos.tableau3[0].distanceKm }}</td>
                        <td>{{ infos.tableau3[1].distanceKm }}</td>
                        <td>{{ infos.tableau3[2].distanceKm }}</td>
                        <td>{{ infos.tableau3[3].distanceKm }}</td>
                        <td>{{ infos.tableau3[4].distanceKm }}</td>
                        <td>{{ infos.tableau3[5].distanceKm }}</td>
                      </tr>
                      <!-- type transport -->
                      <tr>
                        <th scope="row">505</th>
                        <td>Type de transport</td>
                        <td>{{ infos.tableau3[0].typeTransport }}</td>
                        <td>{{ infos.tableau3[1].typeTransport }}</td>
                        <td>{{ infos.tableau3[2].typeTransport }}</td>
                        <td>{{ infos.tableau3[3].typeTransport }}</td>
                        <td>{{ infos.tableau3[4].typeTransport }}</td>
                        <td>{{ infos.tableau3[5].typeTransport }}</td>
                      </tr>
                      <!-- forfait touristique -->
                      <tr>
                        <th scope="row">508</th>
                        <td>
                          S’agissait-il d’un forfait touristique tout compris ?
                        </td>
                        <td>{{ infos.tableau3[0].forfaitTouristique }}</td>
                        <td>{{ infos.tableau3[1].forfaitTouristique }}</td>
                        <td>{{ infos.tableau3[2].forfaitTouristique }}</td>
                        <td>{{ infos.tableau3[3].forfaitTouristique }}</td>
                        <td>{{ infos.tableau3[4].forfaitTouristique }}</td>
                        <td>{{ infos.tableau3[5].forfaitTouristique }}</td>
                      </tr>
                      <!-- montant du forfait -->
                      <tr>
                        <th scope="row">509</th>
                        <td>
                          Montant total payé pour le forfait touristique ?
                        </td>
                        <td>{{ infos.tableau3[0].montantForfait }}</td>
                        <td>{{ infos.tableau3[1].montantForfait }}</td>
                        <td>{{ infos.tableau3[2].montantForfait }}</td>
                        <td>{{ infos.tableau3[3].montantForfait }}</td>
                        <td>{{ infos.tableau3[4].montantForfait }}</td>
                        <td>{{ infos.tableau3[5].montantForfait }}</td>
                      </tr>
                      <!-- cout total du voyage -->
                      <tr>
                        <th scope="row">510</th>
                        <td>Cout total du voyage ?</td>
                        <td>{{ infos.tableau3[0].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau3[1].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau3[2].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau3[3].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau3[4].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau3[5].coutTotalVoyage }}</td>
                      </tr>
                      <!-- poste de dépenses -->
                      <tr>
                        <th scope="row" rowspan="8">511</th>
                        <td>1. Hébergement</td>
                        <td>{{ infos.tableau3[0].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau3[1].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau3[2].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau3[3].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau3[4].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau3[5].posteDepenseHebergement }}</td>
                      </tr>
                      <tr>
                        <td>2. Repas et boissons</td>
                        <td>
                          {{ infos.tableau3[0].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau3[1].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau3[2].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau3[3].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau3[4].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau3[5].posteDepenseRepasBoissons }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3. Achats liés au voyage touristique (nécessaires de
                          voyage, cadeaux, souvenirs, etc.)
                        </td>
                        <td>
                          {{ infos.tableau3[0].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau3[1].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau3[2].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau3[3].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau3[4].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau3[5].posteDepenseAchatsVoyage }}
                        </td>
                      </tr>
                      <tr>
                        <td>4. Activités culturelles</td>
                        <td>
                          {{
                            infos.tableau3[0].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau3[1].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau3[2].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau3[3].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau3[4].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau3[5].posteDepenseActivitesCulturelles
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>5. Activités sportives</td>
                        <td>
                          {{ infos.tableau3[0].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau3[1].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau3[2].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau3[3].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau3[4].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau3[5].posteDepenseActivitesSportives }}
                        </td>
                      </tr>
                      <tr>
                        <td>6. Dépenses médicales</td>
                        <td>
                          {{ infos.tableau3[0].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau3[1].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau3[2].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau3[3].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau3[4].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau3[5].posteDepenseDepensesMedicales }}
                        </td>
                      </tr>
                      <tr>
                        <td>7. Education/Formation</td>
                        <td>
                          {{ infos.tableau3[0].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau3[1].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau3[2].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau3[3].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau3[4].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau3[5].posteDepenseEducationFormation }}
                        </td>
                      </tr>
                      <tr>
                        <td>8. Autres</td>
                        <td>{{ infos.tableau3[0].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau3[1].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau3[2].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau3[3].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau3[4].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau3[5].posteDepenseAutres }}</td>
                      </tr>
                      <!-- nbre de visites -->
                      <tr>
                        <th scope="row">512</th>
                        <td>
                          Combien de fois avez-vous voyagé vers cette
                          destination?
                        </td>
                        <td>
                          {{ infos.tableau3[0].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau3[1].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau3[2].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau3[3].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau3[4].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau3[5].nombreVoyagesVersDestination }}
                        </td>
                      </tr>
                      <!-- activite réalisee -->
                      <tr>
                        <th scope="row">513</th>
                        <td>Quelles activités avez-vous réalisés ?</td>
                        <td>
                          <span
                            v-for="activite in infos.tableau3[0]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau3[1]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau3[2]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau3[3]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau3[4]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau3[5]
                              .activitesRealisees"
                            :key="activite"
                          >
                            {{ activite }}
                          </span>
                        </td>
                      </tr>
                      <!-- satisfactions -->
                      <tr>
                        <th scope="row">514</th>
                        <td>Satisfaction quant au voyage ?</td>
                        <td>{{ infos.tableau3[0].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau3[1].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau3[2].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau3[3].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau3[4].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau3[5].satisfactionVoyage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- tableau 4 -->
              <div
                class="bg-primary"
                v-if="infos.excursionEtranger.statut == 'Oui'"
              >
                <b-row class="text-center mt-1 p-1">
                  <b-col
                    md="10"
                    class="text-left text-white d-flex align-items-center"
                  >
                    <strong>
                      III- DETAILS SUR LES EXCURSIONS A L'ETRANGER
                    </strong>
                  </b-col>
                  <b-col md="2" class="text-right text-white pr-2">
                    <b-img
                      title="Modifier cette section"
                      class="cursor-pointer"
                      width="20"
                      height="20"
                      :src="editIcon"
                      @click="editSection('tableau4')"
                    />
                  </b-col>
                </b-row>
              </div>

              <div
                class="customborder1 p-2"
                v-if="infos.excursionEtranger.statut == 'Oui'"
              >
                <div class="table-responsive text-center">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">601</th>
                        <th scope="col">N° de série du voyage</th>
                        <th scope="col">Voyage(1)</th>
                        <th scope="col">Voyage(2)</th>
                        <th scope="col">Voyage(3)</th>
                        <th scope="col">Voyage(4)</th>
                        <th scope="col">Voyage(5)</th>
                        <th scope="col">Voyage(6)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- nbre personnes voyages -->
                      <tr>
                        <th scope="row">602</th>
                        <td>Nb de personnes ayant effectué ce voyage</td>
                        <td>{{ infos.tableau4[0].nombrePersonne }}</td>
                        <td>{{ infos.tableau4[1].nombrePersonne }}</td>
                        <td>{{ infos.tableau4[2].nombrePersonne }}</td>
                        <td>{{ infos.tableau4[3].nombrePersonne }}</td>
                        <td>{{ infos.tableau4[4].nombrePersonne }}</td>
                        <td>{{ infos.tableau4[5].nombrePersonne }}</td>
                      </tr>
                      <!-- motif voyage -->
                      <tr>
                        <th scope="row"></th>
                        <td>Motif principal du voyage</td>
                        <td>{{ infos.tableau4[0].motifVisite }}</td>
                        <td>{{ infos.tableau4[1].motifVisite }}</td>
                        <td>{{ infos.tableau4[2].motifVisite }}</td>
                        <td>{{ infos.tableau4[3].motifVisite }}</td>
                        <td>{{ infos.tableau4[4].motifVisite }}</td>
                        <td>{{ infos.tableau4[5].motifVisite }}</td>
                      </tr>
                      <!-- destination principale -->
                      <tr>
                        <th scope="row">603</th>
                        <td>Quelle a été la destination principale ?</td>
                        <td>{{ infos.tableau4[0].destinationPrincipale }}</td>
                        <td>{{ infos.tableau4[1].destinationPrincipale }}</td>
                        <td>{{ infos.tableau4[2].destinationPrincipale }}</td>
                        <td>{{ infos.tableau4[3].destinationPrincipale }}</td>
                        <td>{{ infos.tableau4[4].destinationPrincipale }}</td>
                        <td>{{ infos.tableau4[5].destinationPrincipale }}</td>
                      </tr>
                      <!-- distance km -->
                      <tr>
                        <th scope="row">604</th>
                        <td>Quelle a été la distance ? (Km)</td>
                        <td>{{ infos.tableau4[0].distanceKm }}</td>
                        <td>{{ infos.tableau4[1].distanceKm }}</td>
                        <td>{{ infos.tableau4[2].distanceKm }}</td>
                        <td>{{ infos.tableau4[3].distanceKm }}</td>
                        <td>{{ infos.tableau4[4].distanceKm }}</td>
                        <td>{{ infos.tableau4[5].distanceKm }}</td>
                      </tr>
                      <!-- type transport -->
                      <tr>
                        <th scope="row">605</th>
                        <td>Type de transport</td>
                        <td>{{ infos.tableau4[0].typeTransport }}</td>
                        <td>{{ infos.tableau4[1].typeTransport }}</td>
                        <td>{{ infos.tableau4[2].typeTransport }}</td>
                        <td>{{ infos.tableau4[3].typeTransport }}</td>
                        <td>{{ infos.tableau4[4].typeTransport }}</td>
                        <td>{{ infos.tableau4[5].typeTransport }}</td>
                      </tr>
                      <!-- forfait touristique -->
                      <tr>
                        <th scope="row">608</th>
                        <td>
                          S’agissait-il d’un forfait touristique tout compris ?
                        </td>
                        <td>{{ infos.tableau4[0].forfaitTouristique }}</td>
                        <td>{{ infos.tableau4[1].forfaitTouristique }}</td>
                        <td>{{ infos.tableau4[2].forfaitTouristique }}</td>
                        <td>{{ infos.tableau4[3].forfaitTouristique }}</td>
                        <td>{{ infos.tableau4[4].forfaitTouristique }}</td>
                        <td>{{ infos.tableau4[5].forfaitTouristique }}</td>
                      </tr>
                      <!-- montant du forfait -->
                      <tr>
                        <th scope="row">609</th>
                        <td>
                          Montant total payé pour le forfait touristique ?
                        </td>
                        <td>{{ infos.tableau4[0].montantForfait }}</td>
                        <td>{{ infos.tableau4[1].montantForfait }}</td>
                        <td>{{ infos.tableau4[2].montantForfait }}</td>
                        <td>{{ infos.tableau4[3].montantForfait }}</td>
                        <td>{{ infos.tableau4[4].montantForfait }}</td>
                        <td>{{ infos.tableau4[5].montantForfait }}</td>
                      </tr>
                      <!-- cout total du voyage -->
                      <tr>
                        <th scope="row">610</th>
                        <td>Cout total du voyage ?</td>
                        <td>{{ infos.tableau4[0].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau4[1].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau4[2].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau4[3].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau4[4].coutTotalVoyage }}</td>
                        <td>{{ infos.tableau4[5].coutTotalVoyage }}</td>
                      </tr>
                      <!-- poste de dépenses -->
                      <tr>
                        <th scope="row" rowspan="8">611</th>
                        <td>1. Hébergement</td>
                        <td>{{ infos.tableau4[0].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau4[1].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau4[2].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau4[3].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau4[4].posteDepenseHebergement }}</td>
                        <td>{{ infos.tableau4[5].posteDepenseHebergement }}</td>
                      </tr>
                      <tr>
                        <td>2. Repas et boissons</td>
                        <td>
                          {{ infos.tableau4[0].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau4[1].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau4[2].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau4[3].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau4[4].posteDepenseRepasBoissons }}
                        </td>
                        <td>
                          {{ infos.tableau4[5].posteDepenseRepasBoissons }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3. Achats liés au voyage touristique (nécessaires de
                          voyage, cadeaux, souvenirs, etc.)
                        </td>
                        <td>
                          {{ infos.tableau4[0].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau4[1].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau4[2].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau4[3].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau4[4].posteDepenseAchatsVoyage }}
                        </td>
                        <td>
                          {{ infos.tableau4[5].posteDepenseAchatsVoyage }}
                        </td>
                      </tr>
                      <tr>
                        <td>4. Activités culturelles</td>
                        <td>
                          {{
                            infos.tableau4[0].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau4[1].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau4[2].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau4[3].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau4[4].posteDepenseActivitesCulturelles
                          }}
                        </td>
                        <td>
                          {{
                            infos.tableau4[5].posteDepenseActivitesCulturelles
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>5. Activités sportives</td>
                        <td>
                          {{ infos.tableau4[0].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau4[1].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau4[2].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau4[3].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau4[4].posteDepenseActivitesSportives }}
                        </td>
                        <td>
                          {{ infos.tableau4[5].posteDepenseActivitesSportives }}
                        </td>
                      </tr>
                      <tr>
                        <td>6. Dépenses médicales</td>
                        <td>
                          {{ infos.tableau4[0].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau4[1].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau4[2].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau4[3].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau4[4].posteDepenseDepensesMedicales }}
                        </td>
                        <td>
                          {{ infos.tableau4[5].posteDepenseDepensesMedicales }}
                        </td>
                      </tr>
                      <tr>
                        <td>7. Education/Formation</td>
                        <td>
                          {{ infos.tableau4[0].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau4[1].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau4[2].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau4[3].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau4[4].posteDepenseEducationFormation }}
                        </td>
                        <td>
                          {{ infos.tableau4[5].posteDepenseEducationFormation }}
                        </td>
                      </tr>
                      <tr>
                        <td>8. Autres</td>
                        <td>{{ infos.tableau4[0].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau4[1].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau4[2].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau4[3].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau4[4].posteDepenseAutres }}</td>
                        <td>{{ infos.tableau4[5].posteDepenseAutres }}</td>
                      </tr>
                      <!-- nbre de visites -->
                      <tr>
                        <th scope="row">612</th>
                        <td>
                          Combien de fois avez-vous voyagé vers cette
                          destination?
                        </td>
                        <td>
                          {{ infos.tableau4[0].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau4[1].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau4[2].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau4[3].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau4[4].nombreVoyagesVersDestination }}
                        </td>
                        <td>
                          {{ infos.tableau4[5].nombreVoyagesVersDestination }}
                        </td>
                      </tr>
                      <!-- activite réalisee -->
                      <tr>
                        <th scope="row">613</th>
                        <td>Quelles activités avez-vous réalisés ?</td>
                        <td>
                          <span
                            v-for="activite in infos.tableau4[0]
                              .activitesRealisees"
                            :key="activite"
                            >{{ activite }}</span
                          >
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau4[1]
                              .activitesRealisees"
                            :key="activite"
                            >{{ activite }}</span
                          >
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau4[2]
                              .activitesRealisees"
                            :key="activite"
                            >{{ activite }}</span
                          >
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau4[3]
                              .activitesRealisees"
                            :key="activite"
                            >{{ activite }}</span
                          >
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau4[4]
                              .activitesRealisees"
                            :key="activite"
                            >{{ activite }}</span
                          >
                        </td>
                        <td>
                          <span
                            v-for="activite in infos.tableau4[5]
                              .activitesRealisees"
                            :key="activite"
                            >{{ activite }}</span
                          >
                        </td>
                      </tr>
                      <!-- satisfactions -->
                      <tr>
                        <th scope="row">614</th>
                        <td>Satisfaction quant au voyage ?</td>
                        <td>{{ infos.tableau4[0].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau4[1].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau4[2].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau4[3].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau4[4].satisfactionVoyage }}</td>
                        <td>{{ infos.tableau4[5].satisfactionVoyage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center"
                ><div class="mr-2"><b-spinner /></div>

                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="lg"
    >
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";

import FormIdentification from "./sections/FormIdentification.vue";
import FormClassification from "./sections/FormClassification.vue";
import FormTableau1 from "./sections/FormTableau1.vue";
import FormTableau2 from "./sections/FormTableau2.vue";
import FormTableau3 from "./sections/FormTableau3.vue";
import FormTableau4 from "./sections/FormTableau4.vue";

import utilsService from "@/services/utils/utils.service";

import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,

    //identification
    FormIdentification,
    // classification
    FormClassification,
    //tableaux
    FormTableau1,
    FormTableau2,
    FormTableau3,
    FormTableau4,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isSavingForm: false,
      infos: {
        firstname: "KOFFI",
        lastname: "Paul",
        voyagesDernierTrimestre: "Oui",
        raisonNonVoyage: null,
        voyageBenin: {
          statut: "Oui",
          nombre: 2,
        },
        voyageEtranger: {
          statut: "Non",
          nombre: 0,
        },
        excursionBenin: {
          statut: "Non",
          nombre: 0,
        },
        excursionEtranger: {
          statut: "Non",
          nombre: 0,
        },
        tableau1: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: ["Achats", "Monuments"],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
        ],
        tableau2: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
        ],
        tableau3: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
        ],
        tableau4: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcusion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcusion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcusionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcusion: null,
          },
        ],
        auteur: "",
      },

      optionsOuiNon: ["Oui", "Non"],
      optionsNonVoyage: [
        "Pas intéressé",
        "Tarifs élevé de l'hébergement",
        "Tarifs élevé des transports en commun",
        "Insuffisance de transports en commun",
        "Autres",
      ],
      optionsmotifVisite: [
        "Visite de parents d’amis",
        "Funérailles",
        "Vacances, loisirs et détente",
        "Éducation et formation",
        "Santé et soins médicaux",
        "Religion/pèlerinage",
        "Manifestation culturelle ou cultuelle",
        "Achat",
        "Transit",
        "Professionnel",
        "Voyage d’affaire individuel (par exemple, visite d’un fournisseur ou d’un client)",
        "Conférence/congrès/séminaire",
        "Autres",
      ],
      optionsTypeTransport: [
        "Voiture privée",
        "Voiture de location",
        "Voiture de service",
        "Taxi",
        "Transport en commun",
        "Avion",
        "Barque/pirogue",
        "Motocyclette",
        "Autres",
      ],
      optionsLieuResidence: [
        "Hôtel et assimilés",
        "Appartement/Hôtel",
        "Parents/amis",
        "Résidence secondaire",
        "Maison/appartement meublé",
        "Chambre d'hôte",
        "Autres",
      ],
      optionsActivites: [
        "Restaurant/bars/cafés/snack-bars",
        "Achats",
        "Concerts/festival de musique",
        "Monuments",
        "Manifestation culturelle/cultuelle",
        "Randonnée/trekking en forêt/montagne",
        "Visite des parcs nationaux",
        "Golf",
        "Chasse",
        "Manifestation sportive",
        "Théâtre",
        "Evènement familial (mariage, funérailles, baptême, première communion, etc.)",
        "Musées",
        "Zoo/parcs d’attraction",
        "Plage",
        "Formation/conférence/séminaire",
        "Visite de sociétés",
        "Autres",
      ],
      optionsSatisfaction: [
        "Très mécontent",
        "Mécontent",
        "Ni mécontent ni content",
        "Content",
        "Très Content",
      ],

      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  mounted() {
    // return
    if (this.$route.params.data && this.$route.params.preview) {
      // console.log('this.$route.params.data && this.$route.params.preview: ', this.$route.params.data && this.$route.params.preview);
      const { data, preview } = this.$route.params;
      // this.infos = {...data,}
      if (preview) this.infos.editor = this.currentUserI;
      else delete this.infos.editor;
      this.preview = this.$route.params.preview;
    } else {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
      // console.log('onFormsuccess', evt)
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      switch (section) {
        case "identification":
          return "FormIdentification";
        case "classification":
          return "FormClassification";

        case "tableau1":
          return "FormTableau1";
        case "tableau2":
          return "FormTableau2";
        case "tableau3":
          return "FormTableau3";
        case "tableau4":
          return "FormTableau4";

        default:
          break;
      }
    },
    save() {
      const { currentUserId, etablissementDetails, userRoleId } =
        utilsService.currentUserUtils();
      this.isSavingForm = true;
      // console.log('this.infos: ', this.infos)
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = isEditionOperation
        ? "updateFormulaire"
        : "addFormulaire";
      const { _id } = this.infos;
      delete this.infos._id;

      if (!this.infos.raisonNonVoyage) delete this.infos.raisonNonVoyage;

      let data = {
        formType: "forms-tourisme-internes",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
        },
      };

      if (isEditionOperation) {
        data.data.editor = this.currentUserId;
      } else {
        data.data.auteur = currentUserId;
        data.data.auteurRole = userRoleId;
      }

      console.log("data: ", data);
      this[serviceMethode](data)

        .then((response) => {
          console.log(
            "============ response create form: ===========",
            response
          );
          return;

          if (response.status === 200) {
            if (
              this.$store.state.auth &&
              this.$store.state.auth.isAuthenticated
            ) {
              this.$router.push({
                name: "formulaires-list",
                params: { slug: this.$route.params.slug },
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            } else {
              this.$router.push({
                name: "home-public",
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.isSavingForm = false;
        })
        .catch((error) => {
          this.isSavingForm = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = JSON.parse(JSON.stringify(this.infos));

      this.showSectionEditionModal();
    },
    edit() {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
